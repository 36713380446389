var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseModal',{attrs:{"name":"add-usuario","id":"add-usuario","idModal":"add-usuario","size":"lg","title":_vm.editUser ? 'Editar usuário' : 'Cadastrar usuário'},on:{"shown":_vm.openModal},scopedSlots:_vm._u([{key:"footer",fn:function({ cancel }){return [_c('BaseButton',{staticClass:"mr-4",attrs:{"variant":"link-info"},on:{"click":cancel}},[_vm._v(" Cancelar ")]),_c('BaseButton',{attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.onSubmit}},[_vm._v(" Salvar usuário ")])]}}])},[(!_vm.loading)?_c('b-row',{attrs:{"id":"ajuste"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"top-dados"},[_c('div',[(_vm.editUser)?_c('p',[_vm._v("Insira os dados para atualizar o usuário.")]):_c('p',[_vm._v("Insira os dados para cadastrar um usuário.")])])]),_c('b-form',{attrs:{"data-vv-name":"nome"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Nome"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ min: 3, max: 50 }),expression:"{ min: 3, max: 50 }"}],style:({
                  border: _vm.errors.has('nome')
                    ? '1px solid var(--red) !important'
                    : '',
                }),attrs:{"name":"nome","placeholder":"Nome"},model:{value:(_vm.nome),callback:function ($$v) {_vm.nome=$$v},expression:"nome"}}),_c('b-form-invalid-feedback',{staticClass:"errorMsg",attrs:{"state":!_vm.errors.has('nome')}},[_vm._v(" "+_vm._s(_vm.nome.length === 0 ? "Por favor, insira o nome." : _vm.nome.length < 3 || _vm.nome.length > 50 ? "Mínimo de 3 caracteres e máximo de 50 caracteres." : "")+" ")]),(_vm.validSpaceNome)?_c('span',{staticClass:"errorMsg"},[_vm._v(" Espaços inválidos. ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Sobrenome"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ min: 3, max: 50 }),expression:"{ min: 3, max: 50 }"}],style:({
                  border: _vm.errors.has('sobrenome')
                    ? '1px solid var(--red) !important'
                    : '',
                }),attrs:{"name":"sobrenome","placeholder":"Sobrenome"},model:{value:(_vm.sobrenome),callback:function ($$v) {_vm.sobrenome=$$v},expression:"sobrenome"}}),_c('b-form-invalid-feedback',{staticClass:"errorMsg",attrs:{"state":!_vm.errors.has('sobrenome')}},[_vm._v(" "+_vm._s(_vm.sobrenome.length === 0 ? "Por favor, insira o sobrenome." : _vm.sobrenome.length < 3 || _vm.sobrenome.length > 50 ? "Mínimo de 3 caracteres e máximo de 50 caracteres." : "")+" ")]),(_vm.validSpaceSobrenome)?_c('span',{staticClass:"errorMsg"},[_vm._v(" Espaços inválidos. ")]):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"E-mail"}},[_c('b-form-input',{style:({
                  border: _vm.errors.has('email')
                    ? '1px solid var(--red) !important'
                    : '',
                }),attrs:{"type":"text","id":"email","name":"email","placeholder":"E-mail","disabled":_vm.edit},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('b-form-invalid-feedback',{staticClass:"errorMsg",attrs:{"state":!_vm.errors.has('email')}},[_vm._v(" Por favor, insira um e-mail. ")]),(_vm.emailRegexShow && _vm.email !== '')?_c('span',{staticClass:"errorMsg"},[_vm._v(" Por favor, insira um e-mail válido. ")]):_vm._e()],1)],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Telefone","label-for":"phone"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ min: 14, max: 15 }),expression:"{ min: 14, max: 15 }"},{name:"mask",rawName:"v-mask",value:(['(##) ####-####', '(##) #####-####']),expression:"['(##) ####-####', '(##) #####-####']"}],style:({
                  border: _vm.errors.has('phone')
                    ? '1px solid var(--red) !important'
                    : '',
                }),attrs:{"id":"phone","name":"phone","type":"text","placeholder":"Seu telefone"},model:{value:(_vm.telefone),callback:function ($$v) {_vm.telefone=$$v},expression:"telefone"}}),_c('b-form-invalid-feedback',{staticClass:"errorMsg",attrs:{"state":!_vm.errors.has('phone')}},[_vm._v(" Por favor, insira um telofone válido. ")])],1)],1)],1),_c('b-row',{staticClass:"mt-3"},[(_vm.edit === false)?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Senha","label-for":"senha"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ min: 8, max: 50 }),expression:"{ min: 8, max: 50 }"}],style:({
                  border: _vm.errors.has('senha')
                    ? '1px solid var(--red) !important'
                    : '',
                }),attrs:{"id":"senha","name":"senha","type":"text","placeholder":"Senha"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-form-invalid-feedback',{staticClass:"errorMsg",attrs:{"state":!_vm.errors.has('senha')}},[_vm._v(" "+_vm._s(_vm.password.length === 0 ? "Por favor, insira uma senha." : _vm.password.length < 8 || _vm.password.length > 50 ? "Mínimo de 8 caracteres e máximo de 60 caracteres." : "")+" ")]),(_vm.validSpaceSenha)?_c('span',{staticClass:"errorMsg"},[_vm._v(" Espaços inválidos. ")]):_vm._e()],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"6"}},[(!_vm.edit || (_vm.edit && _vm.editUser && _vm.editUser.level !== 'owner'))?_c('b-form-group',{attrs:{"label":"Permissão","label-for":"permission"}},[_c('multiselect',{class:{
                  errorPermission:
                    (!_vm.permission ||
                      !_vm.permission.name ||
                      _vm.permission.name == '') &&
                    _vm.hasError,
                },staticStyle:{"margin-bottom":"4px !important"},attrs:{"placeholder":"Permissão","label":"name","track-by":"id","options":_vm.permissions,"multiple":false,"taggable":true,"searchable":false,"selectLabel":"","deselectLabel":"","selectedLabel":""},model:{value:(_vm.permission),callback:function ($$v) {_vm.permission=$$v},expression:"permission"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Nenhuma permissão encontrada.")])]),(
                  !_vm.permission || !_vm.permission.name || _vm.permission.name == ''
                )?_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorPermission),expression:"errorPermission"}],staticClass:"errorMsg"},[_vm._v("Por favor, insira uma permissão.")]):_vm._e()],1):_vm._e()],1)],1),_c('b-row',{staticClass:"mt-3"},[(_vm.edit === false)?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"CPF","label-for":"document"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##']),expression:"['###.###.###-##']"}],style:({
                  border: _vm.errors.has('document')
                    ? '1px solid var(--red) !important'
                    : '',
                }),attrs:{"id":"document","name":"document","type":"text","placeholder":"Documento","min":"0"},model:{value:(_vm.document),callback:function ($$v) {_vm.document=$$v},expression:"document"}}),_c('b-form-invalid-feedback',{staticClass:"errorMsg",attrs:{"state":!_vm.errors.has('document')}},[_vm._v(" Por favor, insira o cpf. ")]),(!_vm.isDocumentValid && _vm.document.length > 0)?_c('span',{staticClass:"errorMsg"},[_vm._v(" Por favor, insira um cpf válido. ")]):_vm._e()],1)],1):_vm._e()],1),((_vm.editUser && _vm.editUser.level !== 'owner') || !_vm.editUser)?_c('div',{staticClass:"mt-3",attrs:{"cols":"6"}},[_c('BaseSelect',{staticClass:"selectFunil",attrs:{"id":"funis","selectModel":_vm.selectedFunnels,"placeholder":"Selecione um funil","trackname":"title","track-by":"id","array":_vm.campaigns,"searchable":true,"direction":"top","multiple":true,"hide-selected":true,"selectLabel":"","deselectLabel":"","selectedLabel":"","loading":_vm.loadingCampanhas,"watch":true},on:{"change":function($event){return _vm.filterFunnels($event)},"search":function($event){return _vm.debounceCampanhas($event)},"callback":function($event){return _vm.fetchCampanhas($event)}}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Nenhum funil encontrado.")])])],1):_vm._e()],1)],1)],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }