<template>
  <div class="container">
    <BaseHeader title="Gestão de usuários" :navigation="navigation">
    </BaseHeader>

    <div class="container-table">
      <!-- Contador -->
      <div class="box-total" v-if="!loading">
        <div class="total-vendas info"></div>
        <div>
          <p class="total-vendas" v-if="tab == 'usuario'">
            Total de usuários:
            <span>{{ total }}</span>
          </p>
          <p class="total-vendas" v-if="tab == 'grupo'">
            Total de grupos:
            <span>{{ grupos.length }}</span>
          </p>
        </div>
        <div></div>
      </div>

      <div v-else class="d-flex justify-content-center">
        <b-skeleton height="80px" width="1200px"></b-skeleton>
      </div>

      <b-tabs
        class="mt-4 tabListUser"
        nav-class="TabHeader no-border padding"
        active-nav-item-class="TabHeader--active no-border"
        nav-item-class="no-border"
        :no-nav-style="false"
      >
        <!-- Tabela usuários -->
        <b-tab
          class="no-border"
          :title="`Usuários`"
          active
          @click="tab = 'usuario'"
          v-if="!isMobile"
        >
        <div class="new-header-table">
          <b-col
            cols="12"
            md="4"
            class="container-pesquisa"
            :class="{ pd: isMobile }"
            >
            <div class="inputSearch">
              <img src="@/assets/img/icons/search.svg" class="Pointer search" />
              <input
                type="text"
                placeholder="Pesquisar usuário"
                @input="debounce"
                v-model="search"
                class="input-busca"
              />
              </div>
          </b-col>
          <div class="header-table-user" v-if="!loading">
            <BaseButton
              @click="openModal('add-usuario')"
              variant="primary"
              v-if="$store.getters.recursos.nusers == 0 || total < $store.getters.recursos.nusers"
            >
              Adicionar usuário
            </BaseButton>
          </div>

          <div v-else class="d-flex justify-content-end mt-3">
            <b-skeleton height="47px" width="187px"></b-skeleton>
          </div>
        </div>

          <div class="header-table">
            <BaseButton
              variant="primary"
              v-if="$store.getters.recursos.nusers !== 0 && total >= $store.getters.recursos.nusers"
              disabled
            >
              Limite de usuários atingido
            </BaseButton>
          </div>

          <!-- HEADER -->
          <b-row class="Table-header mb-1 mt-5" v-if="!isMobile">
            <b-col
              cols="2"
              class="d-none d-md-block"
              style="padding: 0 10px !important"
            >
              ID
            </b-col>

            <b-col
              cols="5"
              class="d-none d-md-block"
              style="padding-left: 10px"
            >
              Usuário
            </b-col>

            <b-col
              cols="4"
              class="d-none d-md-block"
              style="padding-left: 10px"
            >
              Permissão
            </b-col>

            <b-col
              cols="1"
              class="d-none d-md-block"
              style="
                padding-left: 10px;
                padding-left: 10px;
                display: flex !important;
                justify-content: end;
              "
              >Ações</b-col
            >
          </b-row>
          <!-- END HEADER -->

          <!-- BODY -->
          <template v-if="!loading && usuarios.length > 0 && !isMobile">
            <b-row class="Table-body" v-for="item in usuarios" :key="item.id">
              <!-- id -->
              <b-col cols="2">
                {{ item.id }}
              </b-col>

              <!--título -->
              <b-col
                cols="5"
                style="display: flex; gap: 25px; align-items: center"
              >
                <img
                  class="imgUser"
                  :src="filterPhoto(item.metas, item)"
                  v-if="
                    filterPhoto(item.metas, item) &&
                    filterPhoto(item.metas, item) !== ''
                  "
                  :alt="item.first_name + ' ' + item.last_name"
                />

                <img
                  class="imgUser"
                  src="../../assets/icons/no-seller.svg"
                  v-else
                  :alt="item.first_name + ' ' + item.last_name"
                />

                <div class="nome">
                  {{
                    item && (item.first_name + " " + item.last_name).length > 35
                      ? (item.first_name + " " + item.last_name).substr(0, 36) +
                        "..."
                      : item.first_name + " " + item.last_name
                  }}
                  <p
                    style="
                      font-size: 13px;
                      margin: 0 !important;
                      font-weight: 500;
                      color: var(--gray03);
                    "
                  >
                    {{
                      item && item.email.length > 35
                        ? item.email.substr(0, 36) + "..."
                        : item.email
                    }}
                  </p>
                </div>
              </b-col>

              <b-col cols="4" style="padding: 10px !important">
                <p v-if="item.level == 'seller'" class="levelUser">Vendedor</p>
                <p v-if="item.level == 'gerent_seller'" class="levelUser">
                  Gerente de vendas
                </p>
                <p v-if="item.level == 'blogger'" class="levelUser">Blogger</p>
                <p v-if="item.level == 'templater'" class="levelUser">
                  Templater
                </p>
                <p v-if="item.level == 'commercial_leader'" class="levelUser">
                  Líder Comercial
                </p>
                <p v-if="item.level == 'admin'" class="levelUser">
                  Administrador
                </p>
                <p v-if="item.level == 'owner'" class="levelUser owner">
                  Proprietário 👑
                </p>
              </b-col>

              <b-col
                cols="1"
                class="d-flex dropListUser"
                style="
                  display: flex !important;
                  justify-content: end !important;
                "
              >
                <b-dropdown
                  id="dropPage"
                  right
                  style="margin-top: 0 !important"
                  :disabled="!hasAccess(item) && !hasAccess(item, true)"
                >
                  <template #button-content>
                    <img src="@/assets/img/icons/dots.svg" alt="dots" />
                  </template>
                  <b-dropdown-item
                    v-if="hasAccess(item)"
                    @click="openModal('add-usuario', item)"
                  >
                    Editar</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="hasAccess(item, true)"
                    @click="deleteUser(item)"
                    >Deletar</b-dropdown-item
                  >
                </b-dropdown>
              </b-col>
            </b-row>
          </template>

           <!-- <div v-else>
            <div>
              <b-skeleton
                class="d-flex justify-content-center"
                height="80px"
                width="1200px"
              ></b-skeleton>
            </div>
            <div>
              <b-skeleton
                class="d-flex justify-content-center"
                height="80px"
                width="1200px"
              ></b-skeleton>
            </div>
            <div>
              <b-skeleton
                class="d-flex justify-content-center"
                height="80px"
                width="1200px"
              ></b-skeleton>
            </div>
            <div>
              <b-skeleton
                class="d-flex justify-content-center"
                height="80px"
                width="1200px"
              ></b-skeleton>
            </div>
            <div>
              <b-skeleton
                class="d-flex justify-content-center"
                height="80px"
                width="1200px"
              ></b-skeleton>
            </div>
          </div> -->

          <b-row
            v-if="!loading && !usuarios.length"
            class="Table-body justify-content-center"
          >
            <p class="nao-encontrado">Nenhum usuário foi encontrado</p>
          </b-row>
        </b-tab>

        <!-- Tabela usuários mobile -->
        <b-tab
          class="no-border"
          :title="`Usuários`"
          active
          @click="tab = 'usuario'"
          v-if="isMobile"
          style="max-width: 100vw !important"
        >
          <!-- HEADER -->
          <b-row class="Table-header mb-1">
            <b-col cols="1" class="d-none d-md-block"> ID </b-col>
            <b-col cols="2" class="d-none d-md-block">Usuário</b-col>
          </b-row>
          <!-- END HEADER -->

          <!-- BODY -->
          <template v-if="!loading && usuarios.length > 0">
            <b-row class="Table-body" v-for="item in usuarios" :key="item.ID">
              <!-- id -->
              <b-col cols="1">
                {{ item.id }}
              </b-col>

              <!--título <300 -->
              <b-col cols="7">
                <div class="nome">
                  <p>{{ item.first_name }} {{ item.last_name }}</p>
                  <p style="font-size: 14px">{{ item.email }}</p>
                </div>
              </b-col>
            </b-row>
          </template>

          <b-row
            v-if="!loading && !usuarios.length"
            class="Table-body justify-content-center"
          >
            <p class="nao-encontrado">Nenhum usuário foi encontrado</p>
          </b-row>
        </b-tab>

        <!-- Tabela grupos -->
        <b-tab class="no-border" :title="`Grupos`" @click="tab = 'grupo'">
          <div class="header-table">
            <BaseButton
              @click="openModal('add-grupo', null)"
              variant="primary"
              v-if="!isMobile"
            >
              Adicionar grupo
            </BaseButton>
          </div>

          <!-- HEADER -->
          <b-row
            class="Table-header mb-1 mt-5"
            v-if="!loading && grupos && grupos.length"
          >
            <b-col
              cols="2"
              class="d-none d-md-block"
              style="padding: 0 10px !important"
            >
              ID
            </b-col>

            <b-col cols="2" class="d-none d-md-block" v-if="isMobile">
              Título
            </b-col>

            <b-col cols="4" class="d-none d-md-block" v-if="!isMobile">
              Título
            </b-col>

            <b-col
              cols="4"
              class="d-none d-md-block"
              v-if="!isMobile && client.width < 1300"
            >
              Data de modificação
            </b-col>

            <b-col
              cols="2"
              class="d-none d-md-block"
              v-if="!isMobile && client.width >= 1300"
            >
              Data de modificação
            </b-col>

            <b-col
              cols="4"
              style="
                padding-left: 10px;
                padding-left: 10px;
                display: flex !important;
                justify-content: end;
              "
              class="d-none d-md-block"
              v-if="!isMobile"
              >Ações</b-col
            >
          </b-row>
          <!-- END HEADER -->

          <!-- BODY -->
          <template v-if="!loading && grupos.length > 0">
            <b-row class="Table-body" v-for="grupo in grupos" :key="grupo.ID">
              <!-- id -->
              <b-col cols="2">
                {{ grupo.id }}
              </b-col>

              <!--título -->
              <b-col cols="4" style="padding-left: 20px !important">
                {{ grupo.title }}
              </b-col>

              <b-col cols="4" v-if="!isMobile && client.width < 1300">
                <div v-if="!grupo.updated_at">
                  {{ grupo.created_at | datetime }}
                </div>
                <div v-if="grupo.updated_at">
                  {{ grupo.updated_at | datetime }}
                </div>
              </b-col>

              <b-col
                cols="2"
                v-if="!isMobile && client.width >= 1300"
                style="padding: 0 15px 0 0 !important; text-align: center"
              >
                <div v-if="!grupo.updated_at">
                  {{ grupo.created_at | datetime }}
                </div>
                <div v-if="grupo.updated_at">
                  {{ grupo.updated_at | datetime }}
                </div>
              </b-col>

              <b-col
                cols="4"
                class="d-flex dropListUser"
                v-if="!isMobile"
                style="
                  display: flex !important;
                  justify-content: end !important;
                "
              >
                <b-dropdown
                  id="dropPage"
                  right
                  style="margin-top: 0 !important"
                >
                  <template #button-content>
                    <img src="@/assets/img/icons/dots.svg" alt="dots" />
                  </template>
                  <b-dropdown-item @click="openModal('add-grupo', grupo)">
                    Editar</b-dropdown-item
                  >
                  <b-dropdown-item @click="deleteGrupo(grupo.id)"
                    >Deletar</b-dropdown-item
                  >
                </b-dropdown>
              </b-col>
            </b-row>
          </template>

          <!-- <div
            v-if="loading"
            class="Table-body py-4 d-flex justify-content-center align-items-center"
          >
            <b-spinner variant="dark" label="Spinning"></b-spinner>
          </div> -->

          <b-row
            v-if="!loading && !grupos.length"
            class="Table-body justify-content-center"
          >
            <p class="nao-encontrado">Nenhum grupo foi encontrado</p>
          </b-row>
        </b-tab>
      </b-tabs>

      <!-- END BODY -->
      <AddUsuario @add-usuario="fetchUsers" :editUser="editUser" />
      <AddGrupo
        @add-grupo="fetchUsers"
        :usuarios="usuarios"
        :editGrupo="editUser"
        @atualizar="fetchGroups"
      />
      <b-row>
        <b-col>
          <Paginate
            v-if="usuarios.length > 0 && tab == 'usuario'"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            @to-page="toPage"
            @per-page="perPage"
          />
        </b-col>
      </b-row>
    </div>
    <DeletUserModal 
      :usuario="userSelect"
      :usuarios="returnUsers"
      :cashSellers="allSellersModal"
      @emitAllSellers="emitAllSellers"
      @upDataListUser="upDataListUser"
    >
    </DeletUserModal>
  </div>
</template>
<script>
import AddUsuario from "@/components/Usuarios/addUsuario";
import AddGrupo from "@/components/Usuarios/addGrupo";
import moment from "moment-timezone";
import DeletUserModal from "./deleteUsuario.vue"
import _ from "lodash";

import SellerListService from "@/services/resources/SellerListService";
const serviceSeller = SellerListService.build();

import GrupoService from "@/services/resources/GrupoService";
const serviceGroups = GrupoService.build();

import Vue from "vue";


import Paginate from "@/components/Paginate";

export default {
  name: "listaUsuarios",
  components: {
    AddUsuario,
    AddGrupo,
    Paginate,
    DeletUserModal,
  },
  data() {
    return {
      userSelect:false,
      navigation: [
        { link: "Configurações do sistema", to: this.$route.fullPath },
      ],
      user_level: this.$store.getters.user.user.level,
      tab: "usuario",
      usuarios: [],
      grupos: [],
      allSellersModal:{},
      client: {
        width: 0,
      },
      total: 0,
      totalGrupos: 0,
      editUser: null,
      search: null,
      loading: true,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      recursos: this.$store.getters.recursos,
    };
  },
  computed: {
    returnUsers(){
      return this.usuarios.filter((user) => user !== this.userSelect && user.level !== "blogger" && user.level !== "templater")
    },
    isMobile() {
      return this.client.width <= 768;
    },
    active() {
      return this.$route.name;
    },
    isSeller() {
      return this.$store.getters.isSeller;
    },
    userID() {
      return this.$store.getters.getProfile.id;
    },
    maxLength() {
      return this.$store.getters["sidebarState"] ? 30 : 37;
    },
  },

  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YY HH:ss");
    },
  },

  methods: {
    emitAllSellers(dados){
      this.allSellersModal = dados
    },
    upDataListUser(user){
      let index = this.usuarios.findIndex(usuario => usuario.id == user.id ) 
      this.usuarios.splice(index, 1)
      this.total = this.total-1
    },
    filterPhoto(metas) {
      const photo = metas.find((item) => item.meta_key === "photo");
      if (photo && photo.meta_value) {
        return photo.meta_value;
      } else {
        return "";
      }
    },
    filterPhotoGroup(data) {
      if (data) {
        const user = data.metas.find((x) => x.meta_key === "photo");
        if (user && user.meta_value) {
          return user.meta_value;
        } else {
          return "";
        }
      }
    },

    hasAccess(item, is_delete = false) {
      if (this.user_level === "owner" && item.level === "owner" && !is_delete) {
        return true;
      } else if (this.user_level === "owner" && item.level !== "owner") {
        return true;
      } else if (this.user_level === "admin" && item.level === "owner") {
        return false;
      } else if (this.user_level === "admin" && item.level !== "owner") {
        return true;
      } else if (
        this.user_level === "commercial_leader" &&
        item.level !== "owner" &&
        item.level !== "admin"
      ) {
        return true;
      }

      return false;
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchUsers(this.search, page);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchUsers(this.search);
    },
    deleteGrupo(id) {
      Vue.swal({
        title: "Deletar grupo",
        text: `Atenção, você esta tentando remover um grupo, esta ação não poderá ser desfeita, todos os leads associados não poderão ser filtrados mais por este grupo, tem certeza que deseja prosseguir?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          var data = {
            id: id,
          };
          serviceGroups.destroy(data).finally(() => {
            this.fetchGroups();
            this.$grToast.toast("Grupo deletado com sucesso", {
              title: "Grupos",
              variant: "primary",
              autoHideDelay: 5000,
              appendToast: true,
            });
          });
        }
      });
    },
    deleteUser(user) {
      this.userSelect = user
      this.openModal('delete-user')
    },

    fetchUsers(search = null, page = 1) {
      this.loading = true;
      let data = {
        page: page,
        per_page: this.pagination.perPage,
      };
      if (this.search) {
        data.search = this.search;
      }

      serviceSeller
        .search(data)
        .then((response) => {
          this.usuarios = response.data;
          this.total = response.total;
          this.pagination.totalPages = response.last_page;
          this.pagination.currentPage = response.current_page;
          this.pagination.perPage = response.per_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    fetchGroups() {
      this.loading = true;
      serviceGroups
        .search()
        .then((response) => {
          this.grupos = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleResize() {
      this.client.width = window.innerWidth;
    },
    debounce: _.debounce(function () {
      this.pagination.currentPage = 1;
      this.fetchUsers(this.search);
    }, 1000),
    openModal(data, item) {
      this.editUser = item;
      this.$bvModal.show(data);
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (
      this.$store.getters.user.user.level == "seller" ||
      this.$store.getters.user.user.level == "blogger"
    ) {
        this.$router.push("/dynamicRoute/AccessDenied");
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  mounted() {
    this.loading = true;
    this.fetchUsers();
    this.fetchGroups();
  },
};
</script>

<style lang="scss" scoped>
.nome {
  width: 100%;
  overflow: hidden;
  text-overflow: clip ellipsis;
  white-space: nowrap;
  font-size: 16px;
}

// .box-total {
//  max-width: 90%;
// }
@media (max-width: 768px) {
  .col-1 {
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .col-2 {
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .col-3 {
    display: flex !important;
    flex-wrap: wrap !important;
  }
}

.break {
  word-break: break-all;
}
.metas {
  list-style: none;
  font-size: 11px;

  li {
    word-break: break-all;
  }
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: var(--greenn2);
  margin-right: 15px;
}
.btn-table:first-child:active {
  background: var(--greenn2);
  border: none;
}
.btn-table:first-child img {
  filter: invert(0);
}
.btn-table:nth-child(3) {
  margin-left: 15px;
  margin-right: 20px;
}

.header-table {
  width: 100%;
  display: flex;
  // align-items: center;
  justify-content: flex-end;
}

.new-header-table{
  width: 100%;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  padding-top: 15px;
}

.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.datas .vue-daterange-picker {
  width: 175px;
  font-size: 14px !important;
  color: #81858e !important;
}
.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}
.input-busca {
  width: 100%;
  height: 50px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 10px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 37px;
  top: 19px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
    top: 18px;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
.btn-estatistica {
  margin-left: -30px;
}

.grid-report {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}
@media screen and (max-width: 768px) {
  .Table-header {
    gap: 50px;
    text-align: center;
  }

  .Table-body {
    gap: 50px;
  }
  .header-table {
    display: block;
    padding: 0;
  }
  .flex-sale {
    display: block;
  }
  .flex-sale div {
    margin-left: 0;
  }
  .flex-acoes {
    margin: 15px 0;
  }
  .container-pesquisa {
    text-align: left;
  }
  .search {
    left: 20px;
  }
  .input-busca {
    width: 100%;
  }
  .btn-estatistica {
    margin-left: 0;
    margin-bottom: 20px;
  }

  .grid-report {
    grid-template-columns: 1fr 1fr;
  }
}
.dados-cliente div {
  margin-bottom: 3px;
}
.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}

.offer-name {
  font-weight: normal !important;
  font-size: 13px !important;
}

.whats-cliente {
  font-size: 13px;
  color: var(--greenn);
}
.whats-cliente svg {
  margin-right: 10px;
}
.valor-venda {
  margin-left: 10px;
}

#id_venda {
  position: relative;
}
#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}
.TWO_CREDIT_CARDS {
  position: absolute;
  left: 5px;
  top: -5px;
}

// tipo data
#type {
  margin-bottom: 0 !important;
  margin-left: 10px;
}
.Table-body {
  padding: 15px;
}
.Table-body::before {
  left: 0;
}

.title-report {
  color: var(--gray01);
  font-weight: bold;
  font-size: 20px;
}
.title-report-margin-top {
  margin-top: 41px;
}
.title-report-margin-bottom {
  margin-top: 41px;
}

.default {
  height: 19px;
  padding: 5px;
  overflow-x: hidden;
  font-weight: 600;
  font-size: 10px;
  color: #000;
  background: #0000003b;
  border-radius: 3px;
  // white-space: nowrap;
}

.success {
  height: 19px;
  padding: 5px;
  overflow-x: hidden;
  font-weight: 600;
  font-size: 10px;
  color: var(--greenn);
  background: var(--greenn) 3b;
  border-radius: 3px;
  // white-space: nowrap;
}

div#checkbox-group-all {
  position: relative;
  left: -5px;
}

.imgUser {
  height: 40px;
  width: 40px;
  border-radius: 999px;
}

.levelUser {
  font-size: 14px;
  font-weight: 500;
  color: var(--gray03);
  margin-bottom: 0 !important;
}
.owner {
  width: 130px;
  color: var(--orange);
  font-size: 14px;
  font-weight: 500;
  background-color: var(--orange2);
  padding: 3px 4px;
  border-radius: 5px;
  text-align: center;
}

.avatars {
  display: flex;
  align-items: center;

  .avatar {
    position: absolute;
    transition: all 0.3s;
    &:nth-child(1) {
      left: 10px;
    }
    &:nth-child(2) {
      left: 37px;
    }

    &:nth-child(3) {
      left: 67px;
    }

    &:nth-child(4) {
      left: 97px;
      width: 40px;
      height: 40px;
      border-radius: 9999px;
      background-color: var(--white-medium);
      display: flex;
      align-items: center;
      border: 2px solid #fff;

      justify-content: center;
    }

    &:hover {
      transform: scale(1.1);
      z-index: 9999;
    }
    img {
      border: 2.2px solid #fff;

      height: 40px;
      width: 40px;
      border-radius: 999px;
    }
  }
}
</style>
