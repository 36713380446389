import Rest from "@/services/Rest";

/**
 * @typedef {GrupoService}
 */
export default class GrupoService extends Rest {
  /**
   * @type {String}
   */
  static resource = "groups";
}
