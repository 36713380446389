<template>
  <BaseModal
    name="delete-user"
    id="delete-user"
    idModal="delete-user"
    size="md"
    title="Deletar Usuário"
    @shown="clearModal"
    @hidden="emitAllsellers"
  >
    <b-row>
      <b-col cols="12">
        <div class="top-dados">
          <div>
            <p>Antes de deletar o usuário, distribua os leads para outro</p>
          </div>
        </div>
        <div>
          <label>Usuários</label>
          <BaseSelect
            :selectModel="selectedSeller"
            track-by="id"
            trackname="email"
            :array="sellers"
            placeholder="Pesquise pelo e-mail"
            specificType="sellers"
            @select="selectedSeller = $event"
            :searchable="true"
            selectLabel=""
            deselectLabel=""
            noResult="Oops! Nenhum página encontrada."
            noOptions="Oops! Nenhum vendedor encontrado."
            selectedLabel=""
            @search="debounceVendedor($event)"
            @callback="fetchSellers($event)"
            @change="selectedSeller = $event">
          </BaseSelect>
        </div>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="primary" @click="moverLeads"> Confirmar </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";
import UsuariosService from "@/services/resources/UsuariosService";
const serviceUsuarios = UsuariosService.build();

import SellerListService from "@/services/resources/SellerListService";
const serviceSeller = SellerListService.build();

import SellerAutomation from "@/services/resources/SellerAutomation.js";
const serviceSellerAutomation = SellerAutomation.build();

import moment from "moment";

import Vue from "vue";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      selectedSeller:[],
      stop: false,
      pageVendedor: 1,
      selectedSeller: null,
      sellers:[],
      filters: {
          status: "active",
          data_inicial: "2016-01-01",
          data_final: moment().format('YYYY-MM-DD'),
        },
    };
  },
  props: ["usuario","cashSellers"],
  methods: {
    debounceVendedor: _.debounce(function (query) {
      console.log(query);
      if (query === "") {
        this.removeUserSelect()
      } else {
        this.stop = false;
        this.pageVendedor = 1
        this.fetchSellers(this.pageVendedor, query);
      }
    }, 500),
    fetchSellers(page = 1 ,query = "") {
      if (this.stop) {
        return;
      }
      
      var data = {
        page: `${!query ? 1 : page}&order_by=email&order=ASC&search=${query}&per_page=15`,
      };
      if(page === this.pageVendedor){        
        serviceSeller
          .search(data)
          .then((resp) => {
            this.sellers = this.sellers.concat(resp.data);
            let notSeller = {
              id: 0,
              email: "Sem vendedor",
            };
            if(page == 1){
              this.sellers.unshift(notSeller);
            }
            if(resp.data.length < resp.per_page && query === ""){
              this.stop = true
              this.emitAllsellers()
            }
            console.log("o valor do page", page);
            console.log("o valor do pageVendedor",this.pageVendedor)
            this.pageVendedor = page+1
            this.removeUserSelect();
          })
          .catch((err) => {});
      }
    },
    removeUserSelect(){
      let uniqueMap = new Map();

      this.sellers.forEach(item => {
        if (!uniqueMap.has(item.id)) {
          uniqueMap.set(item.id, item);
        }
      });

      this.sellers = Array.from(uniqueMap.values()).filter((user) => user.id !== this.usuario.id);
    },
    emitAllsellers(isStop){
      let clone = JSON.parse(JSON.stringify(this.sellers))
      let dados = {
        pageVendedor: this.pageVendedor,
        sellers: clone,
        stop: this.stop
      }
      console.log("emitAllSellers",dados);
      this.$emit("emitAllSellers",dados)
    },
    moverLeads() {
      if (!this.selectedSeller) {
        this.$grToast.toast(
          "Selecione o usuário que ira ser repassado os leads",
          {
            title: "Gestão de usuários",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
        return;
      }
      Vue.swal({
        title: "Deletar Usuário",
        text: `Atenção você esta tentando remover um usuário, esta ação não poderá ser desfeita, todos os leads associados não poderão ser filtrados mais por este usuário, tem certeza que deseja prosseguir?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.filters.vendedor = [this.usuario.id];
          var data = {
            id: "assoc",
            leads: "todos",
            user_id:
              this.selectedSeller && this.selectedSeller.id
                ? this.selectedSeller.id
                : 0,
            order: "DESC",
            orderby: 'register',
            total_pagina: "20",
            filters: this.filters,
          };
          serviceSellerAutomation.createId(data).then((resp) => {
            this.selectedSeller = []
            this.deleteUser(this.usuario);
          });
        }
      });
    },
    clearModal(){
      this.selectedSeller = []
      if(JSON.stringify(this.cashSellers) !== '{}'){
        this.stop = this.cashSellers.stop
        this.sellers = this.cashSellers.sellers
        this.pageVendedor = this.cashSellers.pageVendedor
        this.returnePerpage = true
      }
      this.removeUserSelect()
    },
    deleteUser(user) {
      serviceUsuarios.destroy(`/delete/${user.id}`).finally(() => {
        this.$emit("upDataListUser",user);
        this.$grToast.toast("Usuário deletado com sucesso", {
          title: "Usuários",
          variant: "primary",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.$bvModal.hide("delete-user");
      });

    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
label {
  margin: 10px 0 !important;
}
</style>
